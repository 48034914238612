<template>
    <div class="deliveries-list-page">
        <div class="container-fluid">
            <h1>Dostawy {{ day }}</h1>
            <b-button-group class="mr-2 mb-2 d-print-none">
                <b-button variant="secondary" :to="{name: 'adminDeliveriesListProducts',  params: { day: day } }" >Produkty</b-button>
            </b-button-group>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />                
            <b-table if="deliveries" :items="deliveries" :fields="fields" :show-empty="true"
                class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych">
                
                <template #cell(options)="data">
                    <b-button variant="warning" v-on:click="goToSMS(data.item.address.phone,'Uprzejmie informujemy że zamówienie nr: ' + data.item.schedule_id + ' zostało właśnie dostarczone. Dziękujemy i życzymy smacznego');" class="m-1 d-print-none">SMS</b-button>
                    <b-button v-if="data.item.status!=2" variant="success" v-on:click="goDeliveryConfirm(data.item.id)" class="m-1 d-print-none">Doręczono</b-button>
                    <b-button :to="{name: 'AdminDeliveryReport',  params: { deliveryId: data.item.id } }" class="d-print-none">Szczegóły</b-button>
                    <b-button variant="warning" v-on:click="goToCall(data.item.address.phone);" class="m-1 d-print-none">Połącz</b-button>
                </template>
                
            </b-table>
        </div>
    </div>
</template>

<script>import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

export default {
    name: 'adminDeliveriesList',
    data() {
        return {
            isLoading: false,
            day: this.$route.params.day,
            deliveries: null,
            fields: [
                { label: 'Dostawa', key: 'id', sortable: true },
                { label: 'Zamówienie', key: 'schedule.id', sortable: true },
                // { label: 'Kierowca', key: 'driver.fullname', sortable: true },
                // { label: 'Klient', key: 'klient.fullname', sortable: true },
                { label: 'Odbiorca', key: 'address.fullname', sortable: true },
                { label: 'Adres', key: 'address.fulladdresswithcomment', sortable: true },
                { label: 'Produkty', key: 'productslist', sortable: false },
                { label: 'Ilość', key: 'amount', sortable: true },
                // { label: 'Status', key: 'statusname', sortable: false },
                { label: '', key: 'options', sortable: false }
            ]
        }
    },
    computed: {
    },
    mounted(){
        this.getDeliveries()
    },
    methods: {
        goToSMS(number,message) {
            // if (!navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) { // add your own iOS check
            //     t = '?';
            // }
            var t = '?&body=';
            location.href = 'sms:'+ number + t + message;
            console.log('Number:', number, 'Message:',message);
        },
        goToCall(number) {
            location.href = 'sms:'+ number;
            console.log('Number:', number);
        },
        goDeliveryConfirm(id) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.get('/admin/delivery_success/' + id ).then( () => {
                this.isLoading = false;
                this.getDeliveries();
            }).catch( () => {
                this.isLoading = false;
                this.$bvToast.toast(`Błąd potwierdzania dostawy`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                })
            });
        },
        getDeliveries(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.get('/admin/delivery_list/' + this.day ).then( (responce) => {
                this.isLoading = false;
                this.deliveries = responce.data.deliveries;
            }).catch( () => {
                this.isLoading = false;
                this.$bvToast.toast(`Błąd pobierania dostaw.`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                })
            });
        }
    },
}
</script>
